// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom CSS

@import '../css/styles.css';

@import '~bootstrap-icons/font/bootstrap-icons';