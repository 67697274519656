@font-face {
    font-family: 'BrandonGrotesque';
    src: url(../fonts/BrandonGrotesque_Regular.otf);
    }
@font-face {
    font-family: 'BrandonGrotesqueBold';
    src: url(../fonts/BrandonGrotesque_Bold.otf);
    }
@font-face {
    font-family: 'BrandonGrotesqueThin';
    src: url(../fonts/BrandonGrotesque_Thin.otf);
    }
@font-face {
    font-family: 'BrandonGrotesqueMedium';
    src: url(../fonts/BrandonGrotesque_Medium.otf);
    }
@font-face {
    font-family: 'BaskervilleItalicBT';
    src: url(../fonts/BaskervilleItalicBT.woff);
    }

html {
    scroll-snap-type: y mandatory;
    }
body {
    font-family: 'BrandonGrotesque', sans-serif;
    max-width: 1700px;
    letter-spacing: 3px;
    overflow-x: hidden;
}
.pink {
    color: #ff3381;
  }
  
  .hover-pink {
    transition-property: color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
  .hover-pink:hover {
    color: #ff3381;
  }
  .font-thin {
    font-family: "BrandonGrotesqueThin";
  }
  .font-bold {
    font-family: "BrandonGrotesqueBold";
  }
  .font-baskerville {
    font-family: "BaskervilleItalicBT" !important;
  }
  section {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    height: 100vh;
    width: 100vw;
    text-align: center;
  }